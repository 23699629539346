import React, { Fragment } from 'react'

import styles from "./BackgroundBox.module.css";
import Intro from '../../../../components/Intro';
import Button from '../../../../components/Button';


const BackgroundBox = () => {
  return (
    <Fragment>
      <img className={styles.image} src='/bgimage/sass.png' />
      <div className={styles.background}>
        <Intro 
          title="SaaS. Web Apps."
          subTitle="Reinventing technology."
          extendTitleStyle={styles.title}
          extendSubTitleStyle={styles.subTitle}
          />
        <a href='/product?slug=saas-webapp'>
          <Button extendStyle={styles.button}><span>What's Next ?</span></Button>
        </a>
      </div>
    </Fragment>
  )
}

export default BackgroundBox