import React, { useEffect, useState } from 'react';

const ScrollProgressBar = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop === 0) {
      return setScrollProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setScrollProgress(100);
    }

    setScrollProgress((windowScrollTop / totalHeight) * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{  zIndex: 9999, width: "100%" }}>
      <div style={{ height: '10px', backgroundColor: '#0029ff', width: `${scrollProgress}%`, borderRadius:'20px', margin:'10px' }}></div>
    </div>
  );
};

export default ScrollProgressBar;
