import React, {useEffect} from 'react';

import styles from "./Code.module.css";



const Code = () => {
  const codeContentClass = styles.codeContent
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(`.${codeContentClass}`);
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.codeEditor}>
      <div className={styles.hideScrollBar}>
        <div className={styles.codeContent}>
        {'<'}!<span className={styles.pink}>DOCTYPE </span> html{'>'}
        <br />
          {'    <'}
          <span 
            className={styles.pink}
          >html</span> <span className={styles.green}>lang</span>{'='}<span className={styles.yellow}>"fr"</span>{'>'}
          <br />
          {'     <'}<span className={styles.pink}>head</span>{'>'}
          <br />
          {'        <'}<span className={styles.pink}>meta</span> <span className={styles.green}>charset</span>{'='}<span className={styles.yellow}>"UTF-8"</span>{'>'} 
          <br />
          {'        <'}<span className={styles.pink}>title</span>{'>'} Hello Yokr. {'<'}/<span className={styles.pink}>title</span>{'>'} 
          <br />
          {'    <'}/<span className={styles.pink}>head</span>{'>'}
          <br />
          {'    <'}<span className={styles.pink}>body</span>{'>'}
          <br />
          {'        <'}<span className={styles.pink}>h1</span>{'>'}In the world of projects!{'<'}/<span className={styles.pink}>h1</span>{'>'}
          <br />
          
          {'         <'}<span className={styles.pink}>p</span>{'>'} Create your project now.{'<'}/<span className={styles.pink}>p</span>{'>'}
          <br />
          {'          <'}<span className={styles.pink}>ul</span>{'>'} 
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Speed{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Performance{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Unique{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Quickness{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Skyrocket{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Moon{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br />
          {'          <'}/<span className={styles.pink}>ul</span>{'>'}
          <br />
          {'          <'}/<span className={styles.pink}>hr</span>{'>'}
          <br />
          {'        <'}<span className={styles.pink}>h2</span>{'>'}Technologies{'<'}/<span className={styles.pink}>h2</span>{'>'}
          <br />
          {'          <'}<span className={styles.pink}>ol</span>{'>'} 
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Go{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} React{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Node{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Svelte{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} Python{'<'}/<span className={styles.pink}>li</span>{'>'}
          <br/>
          {'              <'}<span className={styles.pink}>li</span>{'>'} PHP{'<'}/<span className={styles.pink}>li</span>{'>'}
  
          <br />
          {'          <'}/<span className={styles.pink}>ol</span>{'>'}
          <br />
          
          
          {'    <'}/<span className={styles.pink}>body</span>{'>'}
          <br />
          {'    <'}/<span className={styles.pink}>html</span>{'>'}
          

        </div>
        </div>
    </div>
  )
}

export default Code