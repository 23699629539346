import React, { useState } from 'react';
import styles from './ContactForm.module.css'
import emailjs from 'emailjs-com'
import ReactLoading from 'react-loading'

const ContactForm = ({ isVisible, formDisplay }) => {

    // State to store form field values
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const[errors, setErrors] = useState([])
    const[success, setSuccess] = useState('')
    const[isLoading, setIsLoading] = useState(false)

    // Handler to update state on form input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        }));
    };

    // Handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        
        let errorsArray = []
        if(formData.name === ''){
            console.log('name error')
            errorsArray.push('Please enter your name')
        }

        if(formData.email === ''){
            console.log('email error')
            errorsArray.push('Please enter your email')
        }

        if(formData.message.length < 10){
            console.log('message error')
            errorsArray.push('The message should be longer then 10 characters')
        }
        

        if(errorsArray.length === 0){
            setIsLoading(true)
            emailjs.sendForm('service_s0u9ibw', 'template_fmlrplb', e.target, 'S2CN2HsxpRJoNChNP')
            .then((result) => {
                setSuccess('Your message was send successfully!')
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    });
                    setIsLoading(false)
            }, (error) => {
                console.log(error)
                setErrors(['Something went wrong while sending the email'])
                setIsLoading(false)
            });
        }else{
            setErrors(errorsArray)
        }
        
        // console.log('Form submitted with data:', formData);
        
    };

    if(isLoading){
        return(
            <div className={`${formDisplay === 'hidden' ? styles.formContainerHidden : styles.formContainerBlock} ${isVisible && styles.show}`}>
                <div className={styles.loadingContainer}>
                    <ReactLoading type={'spinningBubbles'} color='gray' height={100} width={100} />
                </div>
            </div>
        )
    }


    if(success !== ''){
        return(
            <div className={`${formDisplay === 'hidden' ? styles.formContainerHidden : styles.formContainerBlock} ${isVisible && styles.show}`}>
                <div className={styles.successContainer}>
                    <p>{success}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={`${formDisplay === 'hidden' ? styles.formContainerHidden : styles.formContainerBlock} ${isVisible && styles.show}`}>
            <form  onSubmit={handleSubmit} noValidate>
                <p className={styles.title}>We'd love to hear from you!<br/> Drop us a line.</p>
            
                <div className={styles.errorsContainer}>
                    {errors.map((error, index) => {
                        return <p key={index}>{error}</p>
                    })} 
                </div>  
                <div className={styles.formRow}>
                    <div className={styles.inputData}>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        
                        <label htmlFor="name">Name:</label>
                    </div> 
                </div>
                <div className={styles.formRow}>
                    <div className={styles.inputData}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        
                        <label htmlFor="email">Email:</label>
                    </div>
                </div>
                <div>
                    <div className={styles.formRow}>
                        <div className={`${styles.inputData} ${styles.textarea}`}>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="message">Message:</label>
                        </div>
                    </div>
                    
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
        
    );
}

export default ContactForm;