import React, {useEffect, useState} from "react";

import styles from './BlogPost.module.css'
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import SummaryPointsSidebar from "../../components/SummaryPoints";

import allNews from "../../HardcodedData/allNews";


import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const imagesRoot = "/blogImage/"

function findWildCards(post){
    let wildCards = []
    let splitString = post.content.split("{img!}")
    if(splitString.length > 1){
        splitString.slice(1).forEach(element => {
            let splitElement = element.split("{!img}")
            
            wildCards.push(splitElement[0])
            
        });
    }
    return wildCards
}

function replaceWildCards(wildCards, post){
    wildCards.forEach(wildCard => {
        let bodyImage = post.bodyImages.map(image => {
            if(image.wildCard === wildCard) return image
        })
        post.content = post.content.replace(`{img!}${wildCard}{!img}`, `<img src='${imagesRoot}${bodyImage[0]?.imgName}' title='${bodyImage[0]?.imageTitle}' alt='${bodyImage[0]?.imageKeywords}' />`)
    })

    return post.content
}


const BlogPost = () => {

    const urlParams = new URLSearchParams(window.location.search)
    const postId = urlParams.get('id')

    let post = allNews.find(article => article.id === postId)

    if(!post){
        return(
            <div>
                <Navbar />
                <div className={styles.blogPostContainer}>
                    <div className={styles.content}>
                        <h1>404 Post Not Found</h1>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

    const wildCards = findWildCards(post);
    if(wildCards.length > 0){
        post.content = replaceWildCards(wildCards, post)
    }
    
    const [isNavbarIntersecting, setIsNavbarIntersecting] = useState(false)
    const [isFooterIntersecting, setIsFooterIntersecting] = useState(false)

    useEffect(() => {
        document.title = post.title;
        const setMetaContent = (name, content) => {
            let element = document.head.querySelector(`meta[name="${name}"]`);
            if (!element) {
              element = document.createElement('meta');
              element.setAttribute('name', name);
              document.head.appendChild(element);
            }
            element.setAttribute('content', content);
          };
      
        
        setMetaContent('description', post.metaDescription);
        setMetaContent('keywords', post.metaKeywords);
    
        const elementToObserve1 = document.getElementById('navbarIntersection')
        
        const obs1 = new IntersectionObserver((entries) => {
        const entry = entries[0]
        if(entry.isIntersecting){
            setIsNavbarIntersecting(true)
        }else{
            setIsNavbarIntersecting(false)
        }
        }, {
        root: null,
        threshold: 0,
        rootMargin: '0px'
        })
        
        obs1.observe(elementToObserve1)

        const elementToObserve2 = document.getElementById('footerIntersection')
        
        const obs2 = new IntersectionObserver((entries) => {
        const entry = entries[0]
        if(entry.isIntersecting){
            setIsFooterIntersecting(true)
        }else{
            setIsFooterIntersecting(false)

        }
        }, {
        root: null,
        threshold: 0,
        rootMargin: '100px'
        })
        
        obs2.observe(elementToObserve2)
    })

    return(
        <div>
            <Navbar />
            <div className={styles.blogPostContainer}>
                <div className={styles.content}>
                    <h1>{post.title}</h1>
                    <img src={`${imagesRoot}${post.img}`} title={post.imageTitle} alt={post.imageKeywords}/>
                    <div className={styles.contentBody} dangerouslySetInnerHTML={{__html: post.content}} />
                    <hr/>
                    <span className={styles.publishedOn}><i>Published on: {post.publishedOn}</i></span>
                    <div className={styles.socialIcons}>
                        <a>Repost: </a>
                        <a href="#"><FaSquareXTwitter /></a>
                        <a href="#"><FaInstagram /></a>
                        <a href="#"><FaFacebook /></a>
                    </div>
                </div>

                <div className={styles.summaryPoints}>
                    <SummaryPointsSidebar summaryPoints={post.summaryPoints} navbarIntersecting={isNavbarIntersecting} footerIntersecting={isFooterIntersecting}/>
                </div>
            </div>
            <Footer />
        </div>
      
    )
}

export default BlogPost