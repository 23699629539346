import React, {useState, useEffect} from "react";

import styles from './LoadScreenAnimation.module.css'
import LoadingAnimationImage from '../../loadingAnimationImage.png'

const LoadScreenAnimation = () => {
    const [count, setCount] = useState(0);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        const intervalTime = 3000 / 100;
        const interval = setInterval(() => {
        setCount((prevCount) => {
            if (prevCount < 100) {
            return prevCount + 1;
            } else {
            clearInterval(interval);
            setTimeout(() => {setFinished(true);}, 1500)
            
            return prevCount;
            }
        });
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);


    return(
        <div className={styles.loadingContainer}>
            <h1 className={styles.counter}>{finished ? '' : count + '%'} </h1>
            <div className={styles.loadingPercentage}>
                <div className={styles.loadingImageContainer}>
                    <img className={styles.loadingAnimationImage} src={LoadingAnimationImage} />
                </div>
            </div>
            <div className={styles.blackCircle}></div>
        </div>
    )
}

export default LoadScreenAnimation