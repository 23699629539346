import React from "react";
import styles from "./SummaryPoints.module.css"

import ScrollProgressBar from "../ScrollProgressBar";

// const SummaryPoints

const SummaryPoints = ({summaryPoints}) => {
    return(
        <div className={styles.summaryPoints}>
            <h2>Summary</h2>
            <ol>
                {summaryPoints.map((point, index) => {
                    if(point !== ''){
                        return (
                        <li key={index}>
                            <p>
                                {point}
                            </p>
                        </li>
                        )
                    }
                })}
            </ol>
        </div>
    )
}

const SummaryPointsSidebar = ({summaryPoints, navbarIntersecting, footerIntersecting}) => {
    let summaryPointsContainerStyle;
    navbarIntersecting ? summaryPointsContainerStyle = {bottom: '100%', transform: 'translate(0,calc(100% + 180px) )'} : summaryPointsContainerStyle = {bottom: '100%', transform: 'translate(0,calc(100% + 15px) )'}
    footerIntersecting ? summaryPointsContainerStyle = {position: 'absolute', bottom: '100px'} : ''
    return(
        <div className={styles.summaryPointsContainer} style={summaryPointsContainerStyle}>
            <SummaryPoints summaryPoints={summaryPoints} />
            <div className={styles.scrollProgressBarContainer}>
                <ScrollProgressBar />
            </div>
            
            <form  className={styles.formContainer}>
                <h2>Subscribe to Newsletter</h2> 
                <div className={styles.formRow}>
                    <div className={styles.inputData}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                        />
                        <label htmlFor="name">Email:</label>
                    </div> 
                    <button type="submit">Subscribe</button>
                </div>
             </form>
        </div>
    )
}

export default SummaryPointsSidebar