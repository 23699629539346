import React, {useEffect} from 'react'

import styles from "./Projects.module.css"

import Hero from './Content/Hero'
import Footer from '../../components/Footer'
import Catalog from './Content/Catalog'
import FormToggler from '../../components/ContactForm/formToggler'

const Projects = () => {
  useEffect(() => {
    document.title = "Yokr.io - Projects"
  })
  return (
    <div className={styles.container}>
      <Hero />
      <Catalog />
      <FormToggler />
      <Footer />
    </div>
  )
}

export default Projects