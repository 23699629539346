import React, {useEffect} from 'react'
import styles from "./Contact.module.css"

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import ContactForm from '../../components/ContactForm'

import { FaLocationDot, FaPhone, FaDiscord, FaLinkedinIn, FaXTwitter, FaMedium } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

import LoadScreenAnimation from '../../components/LoadScreenAnimation'

const ContactContent = () => {

  return(
    <div className={styles.contactContainer}>
      <LoadScreenAnimation />
      <div className={styles.infoContainer}>
        <div className={styles.contactCard}>
          <div className={styles.sectionTitle}>
            <h1>Contact information</h1>
          </div>
          <div className={styles.contactAdresses}>
            <div className={styles.address}>
              <FaLocationDot className={styles.addressIcon}/>
              <h2 className={styles.addressInfo}> 23 rue de la république,<br/> 38210 Tullins, France</h2>
            </div>
            <div className={styles.address}>
              <FaPhone className={styles.addressIcon}/>
              <h2 className={styles.addressInfo}>+33 6 02 02 89 70</h2>
            </div>
            <div className={styles.address}>
              <IoMdMail className={styles.addressIcon}/>
              <h2 className={styles.addressInfo}>contact@yokr.io</h2>
            </div>
          </div>
          <div className={styles.contactSocials}>
            <a href='https://www.linkedin.com/company/yokr/' target='_blank' className={styles.socialIcon}><FaLinkedinIn /></a>
            <a href='https://twitter.com/yokr_io' target='_blank' className={styles.socialIcon}><FaXTwitter /></a>
            <a href='https://medium.com/@yokr_io' target='_blank' className={styles.socialIcon}><FaMedium /></a>
          </div>
        </div>
      </div>
      <div className={styles.contactFormContainer}>
        <div className={styles.contactForm}>
          <ContactForm isVisible={true} formDisplay='block' />
        </div>
      </div>
    </div>
  )
}

const Contact = () => {
  useEffect(() => {
    document.title = "Yokr.io - Contact"
  })
  return (
    <div className={styles.container}>
      <Navbar />
      <ContactContent />
      <Footer />
    </div>
  )
}

export default Contact