import React, { useState, useEffect } from 'react';
import ContactForm from '.'; 
import styles from './ContactForm.module.css'
import eventEmitter from '../EventEmmiter';
const FormToggler = ({isFormVisible, toggleFormVisibility}) => {
  useEffect(() => {
    const openForm = () => {
      toggleFormVisibility()
    };

    eventEmitter.on('openForm', openForm);

    return () => {
      eventEmitter.removeListener('openForm', openForm);
    };
  });

  return (
    <>
      <ContactForm isVisible={isFormVisible} formDisplay='hidden' />
      <span className={`${styles.formToggler} ${isFormVisible || styles.animateToggle}`} onClick={toggleFormVisibility} style={{display: isFormVisible && 'block'}}>
        <img src='/open-from.webp' />
      </span>
    </>
  );
}

export default FormToggler;
