import React, {useState, useEffect} from "react";

import styles from "./Blog.module.css"

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import allNews from "../../HardcodedData/allNews";

const imagesBasePath = '/blogImage'

let technolodgies = ["Technolodgy", "AI", "Design", "Marketing", "Blockchain", "Development", "QA"]




const SingleBlogPost = ({title, category, img, publishedOn, id}) =>{

    return(
        <div className={styles.blogItem}>
            <img src={`${imagesBasePath}/${img}`}/>
            <div className={styles.blogItemGradient}> 
                
                <div className={styles.itemInfo}>
                    <span className={styles.categoryTag}>{category}</span>
                    <a className={styles.title} href={`/blog-post?id=${id}`}>{title.length > 70 ? title.substr(0, 70) + '...' : title}</a>
                    <p className={styles.publishedOn}>Published on: {publishedOn}</p>
                </div>
            </div>
        </div>
    )
}


const RecentBlogPosts = ({recentNews}) =>{
    return(
        <div>
            <h3 className={styles.sectionTitle}>Recent news</h3>
            <h4 className={styles.sectionSubtitle}>Explore what's new</h4>
            <div className={styles.blogsGrid}>
                {recentNews.map((item, index) => {
                    return <SingleBlogPost key={item.id} title={item.title} img={item.img} category={item.category} publishedOn={item.publishedOn} id={item.id}/>
                })}
            </div>
        </div>
        
    )
}

const ShowMore = ({allNews, displayedNews, handleDisplayedNews}) => {
    if(allNews > displayedNews){
        return <a style={{cursor: 'pointer'}} className={styles.showMore} onClick={handleDisplayedNews}>{'<<'}Show more{'>>'}</a>
    }

    return <></>
}

const OtherNews = ({publishedNews}) => {
    const newsDisplayedAtTime = 8;
    const [newsDisplayed, setNewsDisplayed] = useState(newsDisplayedAtTime);
    let displayedNews = publishedNews.slice(0, newsDisplayed);
    

    function handleDisplayedNews(){
        setNewsDisplayed(newsDisplayed + newsDisplayedAtTime)
    }

    return(
        <div>
            <h4 className={styles.otherNewsTitle}>Other news</h4>
             <div className={styles.otherPostsGrid}>
                {displayedNews.map((item, index) => {
                    return <SingleBlogPost key={item.id} title={item.title} img={item.img} category={item.category} publishedOn={item.publishedOn} id={item.id}/>
                })}
            </div>
            <br/>
            <ShowMore allNews={publishedNews.length} displayedNews={newsDisplayed} handleDisplayedNews={handleDisplayedNews} />
        </div>
       
    )
}

const MostSearchedTags = () => {
    return (
        <div className={styles.mostSearchedTagsContainer}>
            <h5>Most searched tags: </h5>
            {technolodgies.map((technolodgy, index) => {
                return <span key={index} className={styles.mostSearchedTag}>{technolodgy}</span>
            })}
        </div>
    )
}


const BlogsSection = () => {
    const [recentNews, setRecentNews] = useState([]);
    const [publishedNews, setPublishedNews] = useState([]);

    function convertToDateObject(dateStr) {
        const parts = dateStr.split('.');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    function removeFutureArticles(arr) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return arr.filter(article => {
          const articleDate = convertToDateObject(article.publishedOn);
          return articleDate <= today;
        });
    }

    function sortByPublishedDate(arr) {
        return arr.sort((a, b) => {
          const dateA = convertToDateObject(a.publishedOn);
          const dateB = convertToDateObject(b.publishedOn);
          return dateB - dateA;
        });
    }

    function moveFirstFiveToEnd(arr) {
        if (arr.length <= 5) {
          return arr;
        }
        
        const firstFive = arr.slice(0, 5);
        const rest = arr.slice(5);
        
        return [...rest, ...firstFive]; // This does the same as `concat`, combining the arrays
      }

    useEffect(() => {
        document.title = "Yokr.io - Blog"
        let filteredNews = removeFutureArticles(allNews);
        let sortedNews = sortByPublishedDate(filteredNews);
        setRecentNews(sortedNews.slice(0, 5))
        const rearangedNews = moveFirstFiveToEnd(sortedNews)
        setPublishedNews(rearangedNews)
    }, [])

    
    return(
        <div className={styles.blogsContainer}>
            <Navbar />
            <RecentBlogPosts recentNews={recentNews}/>
            <MostSearchedTags />
            <OtherNews publishedNews={publishedNews}/>
            <Footer/>
        </div>
        
    )
}

export default BlogsSection