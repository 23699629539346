import React, {useState} from "react";

import styles from './BecomeClient.module.css'
import FormToggler from "../ContactForm/formToggler";


const BecomeClient = () => {

    const [isFormVisible, setIsFormVisible] = useState(false);

    const toggleFormVisibility = () => {
      setIsFormVisible(!isFormVisible);
    };

    return(
        <div >
            <div className={styles.becomeClientButton} onClick={toggleFormVisibility}>
                
                <a className={styles.becomeClientContent}>&#43; &#xa0;Become Client</a>
            </div>
            <FormToggler isFormVisible={isFormVisible} toggleFormVisibility={toggleFormVisibility}/>
        </div>
    )
}

export default BecomeClient