import React, { useContext } from 'react'

import Logo from '../Logo';
import Grid from '../Grid';
import List from '../List';
import BecomeClient from '../BecomeClient';

import styles from "./Footer.module.css";
import { BreakpointContext } from '../../context/breakpoint';
import { Link } from 'react-router-dom';

const links = {
  1: {
    links: [["Products", "/projects"],["Plugins", "/product?slug=plugins"], ["Web Application", "/product?slug=saas-webapp"], ["Crypto and Data", "/product?slug=crypto-and-data"]], //"Website", "Web applications", "Crypto"
    mx: {
      lg: "1 / span 4",
      md: "1 / span 4",
      sm: "1 / span 6",
    }
  },
  2 : {
    links: [["</>", "/coming-soon"], ['UX/UI', "/coming-soon"],['API and SDK', "/coming-soon"],['Start-Ups', "/coming-soon"]], // "UX/UI", "API and SDK", "Start-Ups"
    mx: {
      lg: "5 / span 4",
      md: "5 / span 4",
      sm: "7 / span 6"
    }
  },
  3: {
    links: [["About Yokr", "/about-us"], ["Privacy Policy", "/privacy-policy"], ["Why Us", "/about-us"], ["Blog", "/blog"]], 
    mx: {
      lg: "9 / span 4",
      md: "9 / span 4",
      sm: "1 / span 12"
    }
  }
}

const Footer = () => {

  const { activeBreakPoint } = useContext(BreakpointContext);

  const isMobile = ["sm", "xs"].includes(activeBreakPoint);

  return (
    <footer className={styles.container} id='footerIntersection'>
      <Grid extendStyle={styles.gridContainer}>
        <Grid.Item 
          clg="1 / span 6"
          cmd="1 / span 6"
          cxs="1 / span 12"
          csm="1 / span 12"
          extendStyle={styles.gridItem}>
          <div className={styles.logoBox}>
            <Logo />
            <p>Lorem Ipsum: Code, Create, Innovate. <br /> Drop us a line via <a href="mailto:contact@yokr.io">contact@yokr.io</a></p>
            <BecomeClient />
          </div>
        </Grid.Item>
        <Grid.Item 
          clg="7 / span 6"
          cmd="7 / span 6"
          cxs="1 / span 12"
          csm="1 / span 12"
        >
          <Grid noGutters >
            {Object.keys(links).map(key => (
              <Grid.Item 
                key={key} 
                clg={links[key].mx.lg}
                cmd={links[key].mx.md}
                csm={links[key].mx.md}
                cxs={links[key].mx.md}
                extendStyle={styles.gridList}
              >
                <List>
                  {(links[key].links || []).map((link, index) => (
                    <List.Item key={index}><Link to={link[1]} style={{ textDecoration: 'none', color: 'inherit' }}>{link[0]}</Link></List.Item>
                  ))}
                </List>
              </Grid.Item>
            ))}
          </Grid>
        </Grid.Item>
      </Grid>
      <div className={styles.copyright}>
        <span>Copyright ©2024 Yokr</span>
      </div>
    </footer>
  )
}

export default Footer