import React, {useEffect} from 'react';

import styles from "./About.module.css";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import { FaTimeline } from "react-icons/fa6";
import { FaProjectDiagram } from "react-icons/fa";
import { LiaStarSolid } from "react-icons/lia";
import { IoMdHappy } from "react-icons/io";

const About = () => {
  useEffect(() => {
    document.title = "Yokr.io - AboutUs"
  })
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.aboutContainer}>
        
        <div className={styles.row}>

          <div className={styles.col}>
            <div className={`${styles.bento} ${styles.generalInfo}`}>
              <h6 className={styles.bentoTitle}>General Info</h6>
              <h1 className={styles.title}>More than technology.<br/> A mindset shift. </h1>
              <p className={styles.description}>Yokr is a forward-looking technology company that specializes in web app development, artificial intelligence, and Web3 technologies. It focuses on embracing and implementing future technologies that are expected to significantly transform daily life and business operations. The company is committed to innovation and adapting to new technological trends to stay at the forefront of the tech industry.</p>
            </div>
          </div>

          <div className={styles.devidedCol}>
            <img src='/yokr-about.webp' alt='Yokr About' />
            <div className={`${styles.bento} ${styles.historyInfo}`}>
              <div className={styles.historyCard}>
                <FaTimeline />
                <div>
                  <h3>3.5</h3>
                  <p className={styles.description}>Years Expirience</p>
                </div>
              </div>
              <div className={styles.historyCard}>
                <FaProjectDiagram />
                <div>
                  <h3>23</h3>
                  <p className={styles.description}>Project Challange</p>
                </div>
              </div>
              <div className={styles.historyCard}>
                <LiaStarSolid />
                <div>
                  <h3>830+</h3>
                  <p className={styles.description}>Positive Reviews</p>
                </div>
              </div>
              <div className={styles.historyCard}>
                <IoMdHappy />
                <div>
                  <h3>100K</h3>
                  <p className={styles.description}>Happy Customers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={styles.row}>
          <div className={`${styles.bento} ${styles.infoCard}`}>
            <h6 className={styles.bentoTitle}>Our mission</h6>
            <h1 className={styles.title}>Advancing <br/> Tomorrow's Tech </h1>
            <p className={styles.description}>Yokr's mission is centered on pioneering advancements in the tech industry, focusing on web app development, AI, and Web3 technologies. The company aims to lead in innovation, constantly adapting to emerging technologies to revolutionize the way we live and work.</p>
          </div>

          <div className={`${styles.bento} ${styles.infoCard}`}>
            <h6 className={styles.bentoTitle}>Our vission</h6>
            <h1 className={styles.title}>Shaping <br/> Digital Future</h1>
            <p className={styles.description}>Yokr envisions a world where technology seamlessly integrates into every aspect of life, enhancing human capabilities and creating new opportunities. The company aims to be at the forefront of this transformation by leveraging the potential of web apps, AI, and Web3. </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>    
  )
}

export default About