import React, {useEffect, useState} from 'react'

import styles from "./Parallex.module.css";

const TextParallax = ({
  text,
}) => {

  const [isIntersecting, setIsIntersecting] = useState(false)
  
  useEffect(() => {
    const elementToObserve1 = document.querySelector(`.${styles.elementToObserve}`)
    
    
    const obs1 = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if(entry.isIntersecting){
        setIsIntersecting(true)
      }else{
        setIsIntersecting(false)
      }
    }, {
      root: null,
      threshold: 0,
      rootMargin: '-20px'
    })
    
    obs1.observe(elementToObserve1)

   
  })

  if(!isIntersecting){
    return(
    <div className={`${styles.parallaxBodyIntersecting} ${styles.elementToObserve}`}>
      <div className={styles.gradientIntersecting} >
        <div className={styles.textIntersecting}>
          <div className={styles.textContentIntersecting}>
            {text}
          </div>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className={`${styles.parallaxBody} ${styles.elementToObserve}`} >
      <div className={styles.gradient} >
        
      </div>
      <div className={styles.text}>
          <div className={styles.textContent}>
            {text}
          </div>
      </div>
    </div>
  )
}

export default TextParallax