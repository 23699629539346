import React, {useState, useEffect} from "react";
import styles from './Product.module.css'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import Button from "../../components/Button";
import LoadScreenAnimation from "../../components/LoadScreenAnimation";

import eventEmitter from "../../components/EventEmmiter";
import products from "../../HardcodedData/products";


const Product = () => {
    const [currentProduct, setCurrentProduct] = useState(null)

    const urlParams = new URLSearchParams(window.location.search)
    const productSlug = urlParams.get('slug')

    useEffect(() => {
        products.forEach(product => {
            if(product.slug === productSlug){
                setCurrentProduct(product)
                return;
            }
        });
    }, [])
    

    const handleFormOpen = () => {
        eventEmitter.emit('openForm', 'Hello World');
    };
    
    if(!currentProduct){
        return(
            <div>
                <LoadScreenAnimation />        
                <Navbar />
                <div className={styles.productContainer}>
                    <h1>404 wrong product</h1>
                </div>
                <Footer />
            </div>
        )
    }

    return(
        <div>
            <LoadScreenAnimation />
            <Navbar />
            <div className={styles.productContainer}>
                <div className={styles.leftSide}>

                    <div className={styles.logoGrid}>
                        <h1>{currentProduct.title}</h1>
                    </div>
                    <div className={styles.messageGrid}>
                        <h1>{currentProduct.text}</h1>
                        <Button children='Create now' onClick={handleFormOpen}  extendStyle={styles.button}/>
                    </div>
                    <div className={styles.emptyGrid}>&nbsp;</div>
                </div>
                <div className={styles.rightSide}>
                    <img src={`/${currentProduct.image}`} alt={currentProduct.title} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Product