const products = [
    {
        slug: 'saas-webapp',
        title: 'SaaS Web App',
        text: 'Create the website of your dreams',
        image: 'website-development-glassmorphism.webp'
    },
    {
        slug: 'crypto-and-data',
        title: 'Crypto and Data',
        text: 'Create the crypto of your dreams',
        image: 'crypto-and-data.webp'
    },
    ,
    {
        slug: 'plugins',
        title: 'Plugins',
        text: 'Create the plugins of your dreams',
        image: 'plugins.webp'
    }
]

export default products